export const experienceData = [{
    es: [
        {
            id: 1,
            company: 'CGI',
            jobtitle: 'Practicas - Desarollador Full Stack',
            startYear: '03/2019',
            endYear: '06/2019',
            description: 'Tecnologías Java Sping JSP, HTML, CSS, MySQL y Javascript.'
        },
        {
            id: 2,
            company: 'PANTALLAZO AZUL',
            jobtitle: 'Desarollador Full Stack',
            startYear: '2018',
            endYear: '2020',
            description: 'Creación de páginas Webs con distintos tipos de tecnologías. HTML, CSS, React, Angular, Javascript, PHP…'
        },
        {
            id: 3,
            company: 'ROCKETFY',
            jobtitle: 'Practicas - Frontend Developer',
            startYear: '2020',
            endYear: 'Present',
            description: 'Diseñador Web Responsive con tecnologías React Hooks, HTML, CSS, SASS, Javascript, Bootstrap'
        },
        {
            id: 4,
            company: 'FREELANCE',
            jobtitle: 'Full Stack Developer',
            startYear: '2020',
            endYear: '2021',
            description: 'Creación de Apps móviles y web. Tecnologías: HTML, CSS, SASS, Bootstrap, Javascript, Java, SQL'
        },
        {
            id: 5,
            company: 'ENERCLIC',
            jobtitle: 'Frontend Developer',
            startYear: '2021',
            endYear: '2022',
            description: 'Creación de nuevos proyectos energéticos (SCADA) con React Hooks. Tecnologías: ReactJS Hooks, CSS/SASS, Javascript, HTML'
        },
        {
            id: 6,
            company: 'INDRA',
            jobtitle: 'Frontend Developer',
            startYear: '2022',
            endYear: 'Actualidad',
            description: 'Arquitecto web en VUE y React Hooks. Tecnologías: Vue JS, React JS, CSS/SASS, Javascript, Pug'
        }
    ],
    en: [
        {
            id: 1,
            company: 'CGI',
            jobtitle: 'Intern - Full Stack Developer',
            startYear: '03/2019',
            endYear: '06/2019',
            description: 'Technologies Java Sping JSP, HTML, CSS, MySQL and Javascript.'
        },
        {
            id: 2,
            company: 'PANTALLAZO AZUL',
            jobtitle: 'Full Stack Developer',
            startYear: '2018',
            endYear: '2020',
            description: 'Creation of Web pages with different types of technologies. HTML, CSS, React, Angular, Javascript, PHP ...'
        },
        {
            id: 3,
            company: 'ROCKETFY',
            jobtitle: 'Intern - Frontend Developer',
            startYear: '2020',
            endYear: 'Present',
            description: 'Web Designer Responsive with technologies React Hooks, HTML, CSS, SASS, Javascript, Bootstrap'
        },
        {
            id: 4,
            company: 'FREELANCE',
            jobtitle: 'Full Stack Developer',
            startYear: '2020',
            endYear: '2021',
            description: 'Creation of mobile and web Apps. Technologies: HTML, CSS, SASS, Bootstrap, Javascript, Java, SQL'
        },
        {
            id: 5,
            company: 'ENERCLIC',
            jobtitle: 'Frontend Developer',
            startYear: '2021',
            endYear: '2022',
            description: 'Creation of new energy projects (SCADA) with React Hooks. Technologies: ReactJS Hooks, CSS/SASS, Javascript'
        },
        {
            id: 6,
            company: 'INDRA',
            jobtitle: 'Frontend Developer',
            startYear: '2022',
            endYear: 'Now',
            description: 'Web architect in VUE and React Hooks. Technologies: Vue JS, React JS, CSS/SASS, Javascript, Pug'
        }
    ]
}]