export const socialsData = {
    github: 'https://github.com/alejandroburgos',
    facebook: '',
    linkedIn: 'https://www.linkedin.com/in/ale-burgos',
    instagram: 'https://www.instagram.com/sawerdj',
    codepen: '',
    twitter: '',
    reddit: 'https://www.reddit.com/user/s4w3r',
    blogger: '',
    medium: '',
    stackOverflow: 'https://stackoverflow.com/users/10639421/sawer-dj',
    gitlab: '',
    youtube: ''
}