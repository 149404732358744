import React, { useContext, useState } from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import Fade from 'react-reveal/Fade';
import { IoMenuSharp, IoHomeSharp } from 'react-icons/io5';
import { HiDocumentText } from 'react-icons/hi';
import { MdPhone } from 'react-icons/md';
import { FaUser, FaPalette} from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';

import './Navbar.scss';
import { headerData } from '../../data/headerData';
import { ThemeContext } from '../../contexts/ThemeContext';
import { ConfigContext } from '../../App';
import { Menu, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormControl, FormControlLabel,Radio, RadioGroup } from '@material-ui/core';
import { 
    greenTheme_Light, greenTheme_Dark, bwTheme_Light, bwTheme_Dark, blueTheme_Light, blueTheme_Dark, redTheme_Light, redTheme_Dark, orangeTheme_Light, orangeTheme_Dark, purpleTheme_Light, purpleTheme_Dark, pinkTheme_Light, pinkTheme_Dark, yellowTheme_Light, yellowTheme_Dark
} from '../../theme/theme'

function Navbar() {
    const { theme, saveTheme, setHandleDrawer } = useContext(ThemeContext);
    const themeLocalStorage = JSON.parse(localStorage.getItem('theme'));

    const {lang, setLang} = useContext(ConfigContext);

    const language = {
        en: {
            home: 'Home',
            about: 'About',
            contact: 'Contact',
            dark: 'Dark',
            light: 'Light',

        },
        es: {
            home: 'Inicio',
            about: 'Sobre mi',
            contact: 'Contacto',
            dark: 'Oscuro',
            light: 'Claro',
        }
    } 
    const allThemes = {
        en: [
            {
                light: [
                    {
                        name: 'Green',
                        value: greenTheme_Light,
                    },
                    {
                        name: 'Black & White',
                        value: bwTheme_Light,
                    },
                    {
                        name: 'Blue',
                        value: blueTheme_Light,
                    },
                    {
                        name: 'Red',
                        value: redTheme_Light,
                    },
                    {
                        name: 'Purple',
                        value: purpleTheme_Light,
                    },
                    {
                        name: 'Pink',
                        value: pinkTheme_Light,
                    },
                    {
                        name: 'Orange',
                        value: orangeTheme_Light,
                    },
                    {
                        name: 'Yellow',
                        value: yellowTheme_Light,
                    },
                ],
                dark: [
                    {
                        name: 'Green',
                        value: greenTheme_Dark,
                    },
                    {
                        name: 'Black & White',
                        value: bwTheme_Dark,
                    },
                    {
                        name: 'Blue',
                        value: blueTheme_Dark,
                    },
                    {
                        name: 'Red',
                        value: redTheme_Dark,
                    },
                    {
                        name: 'Purple',
                        value: purpleTheme_Dark,
                    },
                    {
                        name: 'Pink',
                        value: pinkTheme_Dark,
                    },
                    {
                        name: 'Orange',
                        value: orangeTheme_Dark,
                    },
                    {
                        name: 'Yellow',
                        value: yellowTheme_Dark,
                    },
                ]
            }
        ],
        es: [
            {
                light: [
                    {
                        name: 'Verde',
                        value: greenTheme_Light,
                    },
                    {
                        name: 'Blanco y Negro',
                        value: bwTheme_Light,
                    },
                    {
                        name: 'Azul',
                        value: blueTheme_Light,
                    },
                    {
                        name: 'Rojo',
                        value: redTheme_Light,
                    },
                    {
                        name: 'Morado',
                        value: purpleTheme_Light,
                    },
                    {
                        name: 'Rosa',
                        value: pinkTheme_Light,
                    },
                    {
                        name: 'Naranja',
                        value: orangeTheme_Light,
                    },
                    {
                        name: 'Amarillo',
                        value: yellowTheme_Light,
                    },
                ], 
                dark: [
                {
                    name: 'Verde',
                    value: greenTheme_Dark,
                },
                {
                    name: 'Blanco y Negro',
                    value: bwTheme_Dark,
                },
                {
                    name: 'Azul',
                    value: blueTheme_Dark,
                },
                {
                    name: 'Rojo',
                    value: redTheme_Dark,
                },
                {
                    name: 'Morado',
                    value: purpleTheme_Dark,
                },
                {
                    name: 'Rosa',
                    value: pinkTheme_Dark,
                },
                {
                    name: 'Naranja',
                    value: orangeTheme_Dark,
                },
                {
                    name: 'Amarillo',
                    value: yellowTheme_Dark,
                }
                ]
            }
        ]
    }
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        setHandleDrawer();
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setHandleDrawer();
    };

    const useStyles = makeStyles((t) => ({
        navMenu: {
            fontSize: '2.5rem',
            color: theme.tertiary,
            cursor: 'pointer',
            transform: 'translateY(-10px)',
            transition: 'color 0.3s',
            '&:hover': {
                color: theme.primary,
            },
            [t.breakpoints.down('sm')]: {
                fontSize: '2.5rem',
            },
            [t.breakpoints.down('xs')]: {
                fontSize: '2rem',
            },
        },
        palette: {
            fontSize: '2.5rem',
            color: theme.tertiary,
            cursor: 'pointer',
            transform: 'translateY(-10px)',
            transition: 'color 0.3s',
            justifyContent: 'end',
            marginRight: '1.5rem',
            '&:hover': {
                color: theme.primary,
            },
            [t.breakpoints.down('sm')]: {
                fontSize: '2.5rem',
            },
            [t.breakpoints.down('xs')]: {
                fontSize: '2rem',
            },
        },
        MuiDrawer: {
            padding: '0em 1.8em',
            width: '14em',
            fontFamily: ' var(--primaryFont)',
            fontStyle: ' normal',
            fontWeight: ' normal',
            fontSize: ' 24px',
            background: theme.secondary,
            overflow: 'hidden',
            borderTopRightRadius: '40px',
            borderBottomRightRadius: '40px',
            [t.breakpoints.down('sm')]: {
                width: '12em',
            },
        },
        closebtnIcon: {
            fontSize: '2rem',
            fontWeight: 'bold',
            cursor: 'pointer',
            color: theme.primary,
            position: 'absolute',
            right: 40,
            top: 40,
            transition: 'color 0.2s',
            '&:hover': {
                color: theme.tertiary,
            },
            [t.breakpoints.down('sm')]: {
                right: 20,
                top: 20,
            },
        },
        drawerItem: {
            margin: '2rem auto',
            borderRadius: '78.8418px',
            background: theme.secondary,
            color: theme.primary,
            width: '85%',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            padding: '0 30px',
            boxSizing: 'border-box',
            border: '2px solid',
            borderColor: theme.primary,
            transition: 'background-color 0.2s, color 0.2s',
            '&:hover': {
                background: theme.primary,
                color: theme.secondary,
            },
            [t.breakpoints.down('sm')]: {
                width: '100%',
                padding: '0 25px',
                height: '55px',
            },
        },
        drawerLinks: {
            fontFamily: 'var(--primaryFont)',
            width: '50%',
            fontSize: '1.3rem',
            fontWeight: 600,
            [t.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
            },
        },
        drawerIcon: {
            fontSize: '1.6rem',
            [t.breakpoints.down('sm')]: {
                fontSize: '1.385rem',
            },
        }
    }));

    const classes = useStyles();

    const shortname = (name) => {
        if (name.length > 12) {
            return name.split('j')[0];
        } else {
            return name;
        }
    };

    // first letter of each word in uppercase
    const capitalize = (name) => {
        return name
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join(' ');
    };
   

    const surname = headerData.name.split(' ')[1];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [type, setType] = useState(themeLocalStorage ? capitalize(themeLocalStorage.type) : capitalize(theme.type));
    return (
        <div className='navbar' style={{ backgroundColor: theme.secondary}}>
            <div className='navbar--container'>
                <NavLink
                    to='/'
                    smooth={true}
                    spy='true'
                    duration={2000}
                >
                    <h1 style={{ color: theme.tertiary}}>
                        {shortname(headerData.name)} {surname}
                    </h1>
                </NavLink>
                <div>
                    <FaPalette 
                        className={classes.palette} 
                        onClick={handleClick} />
                        <Menu
                            id="basic-menu"
                            className={classes.menu}
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleClose}
                        >
                            <div className="d-flex p-4" style={{backgroundColor: theme.secondary, color: theme.tertiary}}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        value={type}
                                        name="radio-buttons-group"
                                        onChange={(e) => {
                                            setType(e.target.value);
                                        }}
                                    >
                                        <FormControlLabel value="Light" control={<Radio value="Light" style={{color: themeLocalStorage ? themeLocalStorage.primary : theme.primary}}/>} label={language[lang].light}  />
                                        <FormControlLabel value="Dark" control={<Radio value="Dark" style={{color: themeLocalStorage ? themeLocalStorage.primary : theme.primary}}/>} label={language[lang].dark}  />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        defaultValue={JSON.stringify(themeLocalStorage)}
                                        onChange={(e) => {
                                            saveTheme(e.target.value);
                                        }}
                                        
                                    >
                                        {allThemes[lang].map((theme) => (
                                            theme[type.toLowerCase()].map((theme, i) => (
                                                <FormControlLabel key={i} value={JSON.stringify(theme.value)} 
                                                control={
                                                    <Radio style={{color: themeLocalStorage ? themeLocalStorage.primary : theme.primary}} />
                                                } label={theme.name} />
                                            ))
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </Menu>
                    <IoMenuSharp
                        className={classes.navMenu}
                        onClick={handleDrawerOpen}
                        aria-label='Menu'
                    />
                </div>
            </div>
            <Drawer
                variant='temporary'
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleDrawerClose();
                    } else if (reason !== 'escapeKeyDown') {
                        handleDrawerClose();
                    }
                }}
                anchor='left'
                open={open}
                classes={{ paper: classes.MuiDrawer }}
                className='drawer'
                disableScrollLock={true}
            >
                <div className='div-closebtn'>
                    <CloseIcon
                        onClick={handleDrawerClose}
                        onKeyDown={(e) => {
                            if (e.key === ' ' || e.key === 'Enter') {
                                e.preventDefault();
                                handleDrawerClose();
                            }
                        }}
                        className={classes.closebtnIcon}
                        role='button'
                        tabIndex='0'
                        aria-label='Close'
                    />
                </div>

                <br />

                <div onClick={handleDrawerClose}>
                    <div className='navLink--container'>
                        <Fade left>
                            <NavLink
                                to='/'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <IoHomeSharp
                                        className={classes.drawerIcon}
                                    />
                                    <span className={classes.drawerLinks}>
                                        {language[lang].home}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink
                                to='/#about'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <FaUser className={classes.drawerIcon} />
                                    <span className={classes.drawerLinks}>
                                        {language[lang].about}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade left>
                            <NavLink
                                to='/#resume'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <HiDocumentText
                                        className={classes.drawerIcon}
                                    />
                                    <span className={classes.drawerLinks}>
                                        CV
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        {/* <Fade left>
                            <NavLink
                                to='/#services'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <BsFillGearFill
                                        className={classes.drawerIcon}
                                    />
                                    <span className={classes.drawerLinks}>
                                        Services
                                    </span>
                                </div>
                            </NavLink>
                        </Fade> */}

                        {/* <Fade left>
                            <NavLink
                                to='/#blog'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <FaFolderOpen
                                        className={classes.drawerIcon}
                                    />
                                    <span className={classes.drawerLinks}>
                                        Blog
                                    </span>
                                </div>
                            </NavLink>
                        </Fade> */}

                        <Fade left>
                            <NavLink
                                to='/#contacts'
                                smooth={true}
                                spy='true'
                                duration={2000}
                            >
                                <div className={classes.drawerItem}>
                                    <MdPhone className={classes.drawerIcon} />
                                    <span className={classes.drawerLinks}>
                                        {language[lang].contact}
                                    </span>
                                </div>
                            </NavLink>
                        </Fade>

                        <Fade>
                            <div>
                                <ToggleButtonGroup
                                    value={lang}
                                    fullWidth
                                    exclusive
                                    onChange={(e) => {
                                        setLang(e.target.value);
                                    }}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="es" style={{ outlineColor: lang === 'es' ? theme.primary : '#838383', color: theme.tertiary ,outlineWidth: '2px', outlineStyle: 'solid', margin: '2px'}}>
                                        ES
                                    </ToggleButton>
                                    <ToggleButton value="en" style={{ outlineColor: lang === 'en' ? theme.primary : '#838383', color: theme.tertiary, outlineWidth: '2px', outlineStyle: 'solid', margin: '2px'}}>
                                        EN
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Fade>

                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default Navbar;
