import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles, styled } from '@material-ui/core/styles';
import { AiOutlineHome } from "react-icons/ai";
import { ConfigContext } from "./../../App";

import './Pricing.scss'
import { ThemeContext } from '../../contexts/ThemeContext';
import { pricingData } from '../../data/pricingData'
import { headerData } from '../../data/headerData'

import { PricesCard } from '../../components/Prices/PricesCard';
import Accordions from '../../components/Accordion/Accordion';
import { Navbar } from '../../components';
import NewWordpress from './NewWordpress';
import { Pricing } from '..';
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.primary,
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));
export const HomePricing = () => {
        const { theme } = useContext(ThemeContext);
    const configLanguage = useContext(ConfigContext);
    const language = {
        en: {
            title: 'Price plan',
            monthly: 'Monthly',
            newWebsite: 'New website',
            year: 'Year',
            pay: 'All payments must be made between the 1st and the 5th of each month.' ,
            maintenance: 'Maintenance',
            placeholder: {
                name: 'Name',
                email: 'Email',
                message: 'Message',
                message2: 'Write your message here...',
            }
        },
        es: {
            title: 'Precios',
            monthly: 'Mes',
            newWebsite: 'Sitio web nuevo',
            year: 'Año',
            pay: 'Todos los pagos deben realizarse entre el 1 y el 5 de cada mes.' ,
            maintenance: 'Mantenimiento',
            placeholder: {
                name: 'Nombre',
                email: 'Correo',
                message: 'Mensaje',
                message2: 'Escribe tu mensaje aquí...',
            }
        }
    } 
    const [typePrice, setTypePrice] = useState('m')
    const [isNewWeb, setIsNewWeb] = useState(false)

    const handleChangeWeb = (e) => {
        setIsNewWeb(e.target.checked)
    }
    return (
    <>
        <Navbar />
        <div style={{backgroundColor: theme.secondary}}>
            <Helmet>
                <title>{headerData.name} | {language[configLanguage.lang].title}</title>
            </Helmet>

            <div className="pricing-header" style={{backgroundColor:theme.primary}}>
                <h1 style={{color: theme.secondary}}>Web</h1>
            </div>
            <div className="toggle justify-content-center"><div>
                    <label style={{marginRight: 20}}>{language[configLanguage.lang].newWebsite}</label>
                        <FormControlLabel
                            control={<MaterialUISwitch sx={{ m: 1 }} onChange={handleChangeWeb}/>}
                        />
                    <label>{language[configLanguage.lang].maintenance}</label>
                </div>
            </div>
            {
                !isNewWeb ?
                <NewWordpress /> 
                :
                <Pricing />

            }
        </div>
    </>
  )
}
