import React from 'react'
// import terminal from component terminal
import Terminal from '../../components/Terminal/Terminal'
function TerminalPage () {
    return (
        <div>
            <Terminal />        
        </div>
    )
}

export default TerminalPage
