export const pricingData = [
  {
    es: [
      {
        id: 0,
        priceName: "Básico",
        price: 50,
        data: [
          {
            plugins_update: true,
            remove_virus: false,
            change_designs: true,
            woocomerce_settings: true,
            custom_designs: 1,
          },
        ],
        img: "https://s22.postimg.cc/8mv5gn7w1/paper-plane.png",
      },
      {
        id: 1,
        priceName: "Standard",
        price: 70,
        data: [
          {
            plugins_update: true,
            remove_virus: true,
            change_designs: true,
            woocomerce_settings: true,
            custom_designs: 3,
          },
        ],
        img: "https://s28.postimg.cc/ju5bnc3x9/plane.png",
      },
      {
        id: 2,
        priceName: "Premium",
        price: 99,
        data: [
          {
            plugins_update: true,
            remove_virus: true,
            change_designs: true,
            woocomerce_settings: true,
            add_products: 20,
            custom_designs: 7,
            backups: 1,
            social_settings: true,
          },
        ],
        img: "https://s21.postimg.cc/tpm0cge4n/space-ship.png",
      },
    ],
    en: [
      {
        id: 0,
        priceName: "Basic",
        price: 50,
        data: [
          {
            web: true,
            designs: 0,
            socialAdds: false,
          },
        ],
        img: "https://s22.postimg.cc/8mv5gn7w1/paper-plane.png",
      },
      {
        id: 1,
        priceName: "Standard",
        price: 70,
        data: [
          {
            web: true,
            designs: 3,
          },
        ],
        img: "https://s28.postimg.cc/ju5bnc3x9/plane.png",
      },
      {
        id: 2,
        priceName: "Premium",
        price: 99,
        data: [
          {
            web: true,
            designs: 7,
          },
        ],
        img: "https://s21.postimg.cc/tpm0cge4n/space-ship.png",
      },
    ],
  },
];
