import React,{ useContext} from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import { ThemeContext } from '../../contexts/ThemeContext';
import { wordpressPagesData } from '../../data/wordpressPageData'
import { HiArrowRight } from "react-icons/hi";
import { ConfigContext } from "../../App";

import './WordpressProjects.scss'
import SingleProject from '../Projects/SingleProject/SingleProject';


export const WordpressProjects = () => {
    
    const { theme } = useContext(ThemeContext);
    const configLanguage = useContext(ConfigContext);
    const language = {
        en: {
            title: 'Wordpress Pages',
            btnAll: 'View All',
        },
        es: {
            title: 'Páginas Wordpress',
            btnAll: 'Ver Todos',
        }
    } 
    const useStyles = makeStyles(() => ({
        viewAllBtn : {
            color: theme.tertiary, 
            backgroundColor: theme.primary,
            transition: 'color 0.2s',
            "&:hover": {
                color: theme.secondary, 
                backgroundColor: theme.primary,
            }
        },
        viewArr : {
            color: theme.tertiary, 
            backgroundColor: theme.secondary70,
            width: '40px',
            height: '40px',
            padding: '0.5rem',
            fontSize: '1.05rem',
            borderRadius: '50%',
            cursor: 'pointer',
            transition: 'background-color 0.2s',
            "&:hover": {
                color: theme.tertiary, 
                backgroundColor: theme.secondary,
            }
        },
    }));

    const classes = useStyles();

    return (
    <>
        {wordpressPagesData.length > 0 && (
                <div className="projects" id="projects" style={{backgroundColor: theme.secondary}}>
                    <div className="projects--header">
                        <h1 style={{color: theme.primary}}>{language[configLanguage.lang].title}</h1>
                    </div>
                    <div className="projects--body">
                        <div className="projects--bodyContainer">
                            {wordpressPagesData[0][configLanguage.lang].slice(0, 4).map(projects => (
                                    <SingleProject
                                        theme={theme}
                                        key={projects.id}
                                        id={projects.id}
                                        name={projects.projectName}
                                        desc={projects.projectDesc}
                                        tags={projects.tags}
                                        code={projects.code}
                                        demo={projects.demo}
                                        image={projects.image}
                                />
                            ))}
                        </div> 

                        {wordpressPagesData[0][configLanguage.lang].length > 3 && (
                            <div className="projects--viewAll">
                                <Link to="/wordpress-projects">
                                    <button className={classes.viewAllBtn}>
                                        {language[configLanguage.lang].btnAll}
                                        <HiArrowRight className={classes.viewArr} />
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}
    </>
  )
}
