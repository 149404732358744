export const aboutData = {
    image: 1,
    es: {
        title: "Sobre mi",
        description1: "Mi nombre es Alejandro Burgos. Soy desarrollador front end, resido en Málaga .",
        description2: "Durante el día trabajo como desarrollador principal en una empresa internacional y en la noche trabajo en proyectos independientes para crear mis propios productos. En la actualidad estoy aprendiendo realidad virtual e IA. Me encanta aprender y explorar nuevos campos.",
    },
    en: {
        title: "About me",
        description1: "My name is Alejandro Burgos. I am a front end developer, I live in Málaga.",
        description2: "During the day I work as a principal developer in an international company and at night I work on independent projects to create my own products. At the moment I am learning virtual reality and AI. I love to learn and explore new fields.",
    }
}