import React, { createContext, useState } from 'react'
import { useEffect } from 'react'

import { themeData } from '../data/themeData'

export const ThemeContext = createContext()

function ThemeContextProvider(props) {
    // eslint-disable-next-line
    const [theme, setTheme] = useState(themeData.theme)
    const [drawerOpen, setDrawerOpen] = useState(false)

    useEffect(() => {
        if (!localStorage.getItem('theme') || localStorage.getItem('theme') === 'undefined' || localStorage.getItem('theme') === 'null') {
            localStorage.setItem('theme', JSON.stringify(theme));
        } else {
            setTheme(JSON.parse(localStorage.getItem('theme')))
        }
    }, [])

    const saveTheme = (selectTheme) => {
        if (!localStorage.getItem('theme')) {
            localStorage.setItem('theme', JSON.stringify(theme));
        } 
        localStorage.setItem('theme', selectTheme);
        setTheme(JSON.parse(selectTheme))
    }

    const setHandleDrawer = () => {
        setDrawerOpen(!drawerOpen)
    }

    const value = { theme, saveTheme, drawerOpen, setHandleDrawer }
    return (
        <ThemeContext.Provider value={value}>
            {props.children}
        </ThemeContext.Provider>
    )
}


export default ThemeContextProvider