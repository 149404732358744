/* eslint-disable */
import { 
    greenTheme_Light, greenTheme_Dark, bwTheme_Light, bwTheme_Dark, blueTheme_Light, blueTheme_Dark, redTheme_Light, redTheme_Dark, orangeTheme_Light, orangeTheme_Dark, purpleTheme_Light, purpleTheme_Dark, pinkTheme_Light, pinkTheme_Dark, yellowTheme_Light, yellowTheme_Dark
} from '../theme/theme'


export const themeData = {
    theme: orangeTheme_Dark
}


// Choose theme from above