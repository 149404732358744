export const newWebDataPrices = [{
    es: [{
        id: 0,
        priceName: 'Básica informativa',
        price: 300,
        data: []
    },
    {
        id: 1,
        priceName: 'Tienda online',
        price: 800,
        data: []
    },
    {
        id: 2,
        priceName: 'Web a medida',
        price: 999,
        data: []
    }
    ],
    en: [{
        id: 0,
        priceName: 'Informative basic',
        price: 300,
        data: []
    },
    {
        id: 1,
        priceName: 'Online store',
        price: 800,
        data: []
    },
    {
        id: 2,
        priceName: 'Custom web',
        price: 999,
        data: []
    }
    ]
}]

