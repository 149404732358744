import * as React from "react";
import "./Accordion.scss";
const language = {
  en: [
    {
      title: "Plugins update",
      description:
        "It will keep up with the latest plugin updates whenever possible.",
    },
    {
      title: "Web design",
      description:
        "Changes in the design of the web will be modified except for complete themes.",
    },
    {
      title: "Woocomerce settings",
      description:
        "Adjustments will be made in the trade, such as: payment methods, shipments, forms, etc.",
    },
    {
      title: "Virus removal",
      description:
        "Viruses and malware will be removed from the web whenever it exists.",
    },
    {
      title: "Add products",
      description:
        "Up to thirty (30) products will be added to the web but not their modification.",
    },
    {
      title: "Custom designs",
      description:
        "Custom designs will be made for the web or social networks.",
    },
    {
      title: "Backups",
      description:
        "Backup copies of the web will be made. One (1) cloud copy and one (1) local copy will be created.",
    },
    {
      title: "Social media settings",
      description:
        "The web will be adjusted to be compatible with social networks. Adjustments will also be made in social networks, such as: creation of ads, creation of campaigns, role adjustments, etc.",
    },
  ],
  es: [
    {
      title: "Actualización de plugins",
      description:
        "Se mantendrá al día con las últimas actualizaciones de plugins siempre que sea posible.",
    },
    {
      title: "Diseño web y páginas",
      description:
        "Se modificaran cambios en el diseño de la web exceptuando temas completos y nuevas páginas.",
    },
    {
      title: "Ajustes del comercio",
      description:
        "Se realizarán ajustes en el comercio como por ejemplo: métodos de pago, envíos, formularios etc.",
    },
    {
      title: "Eliminación de virus",
      description:
        "Se eliminarán virus y malware de la web siempre que exista.",
    },
    {
      title: "Agregar productos",
      description:
        "Se agregarán hasta treinta (30) productos a la web pero no su modificación.",
    },
    {
      title: "Diseños personalizados",
      description:
        "Se realizarán diseños personalizados para la web o redes sociales.",
    },
    {
      title: "Copias de seguridad",
      description:
        "Se realizarán copias de seguridad de la web. Se creará una (1) copía en nube y otra (1) copia en local mensualmente.",
    },
    {
      title: "Ajustes en redes sociales",
      description:
        "Se ajustara la web para que sea compatible con las redes sociales. También se realizarán ajustes en las redes sociales, como por ejemplo: creación de anuncios, creación de campañas, ajustes de roles etc.",
    },
  ],
};
function Accordions({ theme, lang }) {
  return (
    <div className="container-accordion">
      <section className="faq">
        {language[lang].map((item, i) => (
          <Question
            key={i}
            title_accordion={item.title_accordion}
            title={item.title}
            desc={item.description}
            theme={theme}
          />
        ))}
      </section>
    </div>
  );
}

const Question = (props) => {
  const [isActive, setActive] = React.useState(false);
  const handleClick = (id) => {
    setActive(!isActive);
  };
  return (
    <div
      key={props.i}
      className="question-wrapper"
      style={{ borderBottom: `1px solid ${props.theme.primary}` }}
    >
      <div
        className="question"
        id={props.id}
        onClick={() => handleClick(props.id)}
      >
        <h3>{props.title}</h3>
        <button>
          <svg
            className={isActive ? "active" : ""}
            viewBox="0 0 320 512"
            width="100"
            title="angle-down"
          >
            <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" />
          </svg>
        </button>
      </div>
      <div className={isActive ? "answer active" : "answer"}>{props.desc}</div>
    </div>
  );
};

export default Accordions;
