export const educationData = [
        {
            id: 1,
            institution: 'IES Campanillas (PTA Málaga)',
            course: 'Grado medio - Microinformática y Redes Bilingüe',
            startYear: '2016',
            endYear: '2018'
        },
        {
            id: 2,
            institution: 'IES Montecillos (Coín, Málaga)',
            course: 'Grado Superior - Desarollo de Aplicaciones Multiplataformas',
            startYear: '2018',
            endYear: '2020'
        },
        {
            id: 3,
            institution: 'Udemy',
            course: 'Master JavaScript: JS, jQuery, Angular, NodeJS.',
            startYear: '2020',
            endYear: '2020'
        },
        {
            id: 4,
            institution: 'Udemy',
            course: 'CRUD Angular + NET Core + Entity Framework Core + MySql',
            startYear: '2020',
            endYear: '2020'
        },
        {
            id: 5,
            institution: 'egghead.io',
            course: 'ReactJS (60h)',
            startYear: '2020',
            endYear: '2020'
        },
        {
            id: 6,
            institution: 'Udemy',
            course: 'ReactJS + Webpack + SASS',
            startYear: '2020',
            endYear: '2020'
        }
    ]