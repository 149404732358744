import React from 'react';
import './Terminal.css'
import { useNavigate } from "react-router-dom";
import { aboutData } from '../../data/aboutData'
import { projectsData } from '../../data/projectsData'
import { experienceData } from '../../data/experienceData'
import { educationData } from '../../data/educationData'
import { skillsData } from '../../data/skillsData'

const CMD = () => {
	const [ theme, setTheme ] = React.useState('dark')
	const themeVars = theme === 'dark' ? {
		app: {backgroundColor: '#333444'},
		terminal: {boxShadow: '0 2px 5px #111'},
		window: {backgroundColor: '#222345', color: '#F4F4F4'},
		field: {backgroundColor: '#222333', color: '#F4F4F4', fontWeight: 'normal'},
		cursor: {animation : '1.02s blink-dark step-end infinite'}
	} : {
		app: {backgroundColor: '#ACA9BB'},
		terminal: {boxShadow: '0 2px 5px #33333375'},
		window: {backgroundColor: '#5F5C6D', color: '#E3E3E3'},
		field: {backgroundColor: '#E3E3E3', color: '#474554', fontWeight: 'bold'},
		cursor: {animation : '1.02s blink-light step-end infinite'}
	}
	
	return <div id="app" style={themeVars.app}>
		<Terminal theme={themeVars} setTheme={setTheme}/>
	</div>
}
const Terminal = ({ theme, setTheme }) => {
	const [ maximized, setMaximized ] = React.useState(false)
	const [ title, setTitle ] = React.useState('Terminal')
    let navigate = useNavigate();
	const handleClose = () => (navigate(`/`))
	const handleMinMax = () => {
		setMaximized(!maximized)
		document.querySelector('#field').focus()
	}
	
	return <div id="terminal" style={maximized ? {height: '100vh', width: '100vw', maxWidth: '100vw'} : theme.terminal}>
		<div id="window" style={theme.window}>
			<button className="btn red" onClick={handleClose}/>
			<button id="useless-btn" className="btn yellow"/>
			<button className="btn green" onClick={handleMinMax}/>
			<span id="title" style={{color: theme.window.color}}>{title}</span>
		</div>
		<Field theme={theme} setTheme={setTheme} setTitle={setTitle}/>
	</div>
}
class Field extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			commandHistory: [],
			commandHistoryIndex: 0,
			fieldHistory: [{text: 'Consola'}, {text: 'Escriba HELP para ver la lista completa de comandos', hasBuffer: true}],
			userInput: '',
			isMobile: false
		}
		this.recognizedCommands = [{
			command: 'help',
			purpose: 'Proporciona información de ayuda para los comandos de la consola'
		}, {
			command: 'date',
			purpose: 'Muestra la fecha actual'
		}, {
			command: 'start',
			purpose: 'Inicia una URL específica en una nueva pestaña o ventana separada',
			help: [
				'START <URL>',
				'Inicia una URL específica en una nueva pestaña o ventana separada',
				'',
				'URL......................El sitio web que desea abrir'
			]
		}, {
			command: 'cls',
			purpose: 'Limpia la consola'
		}, {
			command: 'cmd',
			purpose: 'Abre otra instancia de consola.'
		}, {
			command: 'theme',
			purpose: 'Cambia el color de la consola',
			help: [
				'THEME [-l, -light, -d, -dark]',
				'Establece el tema de la consola',
				'',
				'-l, -light...............Tema claro',
				'-d, -dark................Tema oscuro'
			]
		}, {
			command: 'exit',
			purpose: 'Vuelve a la página pricipal de Alejandro'
		}, {
			command: 'time',
			purpose: 'Muestra la hora actual'
		}, {
			command: 'about',
			isMain: true,
			purpose: 'Muestra la información de contacto de Alejandro'
		}, {
			command: 'experience',
			isMain: true,
			purpose: 'Muestra la experiencia laboral de Alejandro'
		}, {
			command: 'skills',
			isMain: true,
			purpose: 'Muestra las habilidades de Alejandro'
		}, {
			command: 'contact',
			isMain: true,
			purpose: 'Muestra la información de contacto de Alejandro'
		}, {
			command: 'projects',
			isMain: true,
			purpose: 'Muestra los proyectos de Alejandro'
		}, {
			command: 'project',
			isMain: true,
			purpose: 'Muestra la información de un proyecto de Alejandro',
			help: [
				'PROJECT <TITLE>',
				'Muestra la información de un proyecto de Alejandro',
				'Lista de los proyectos:',
				...projectsData.map(project => project.projectName),
				'',
				'TITLE....................El título del proyecto que quieres ver'
			]
		}, {
			command: 'title',
			purpose: 'Cambia el título de la consola',
			help: [
				'TITLE <INPUT>',
				'Setea el título de la consola',
				'',
				'INPUT....................El nombre que quieres ponerle a la consola'
			]
		}]
		this.handleTyping = this.handleTyping.bind(this)
		this.handleInputEvaluation = this.handleInputEvaluation.bind(this)
		this.handleInputExecution = this.handleInputExecution.bind(this)
		this.handleContextMenuPaste = this.handleContextMenuPaste.bind(this)
	}
	componentDidMount() {
		if (typeof window.orientation !== "undefined" || navigator.userAgent.indexOf('IEMobile') !== -1) {
			this.setState(state => ({
				isMobile: true,
				fieldHistory: [...state.fieldHistory, {isCommand: true}, {
					text: `Desgraciadamente no puedes usar la consola en un dispositivo móvil. Por favor, usa un ordenador.`,
					isError: true,
					hasBuffer: true
				}]
			}))
		}
		
		const userElem = document.querySelector('#field')
		
		// userElem.focus()
		
		document.querySelector('#useless-btn').addEventListener('click', () => this.setState(state => ({
			fieldHistory: [...state.fieldHistory, {isCommand: true}, {text: 'SYS >> Este botón es inutil.', hasBuffer: true}]
		})))
	}
	componentDidUpdate() {
		const userElem = document.querySelector('#field')
		
		userElem.scrollTop = userElem.scrollHeight
	}
	handleTyping(e) {
		e.preventDefault()
		
		const { key, ctrlKey, altKey } = e
		const forbidden = [
			...Array.from({length: 12}, (x, y) => `F${y + 1}`),
			'ContextMenu', 'Meta', 'NumLock', 'Shift', 'Control', 'Alt',
			'CapsLock', 'Tab', 'ScrollLock', 'Pause', 'Insert', 'Home',
			'PageUp', 'Delete', 'End', 'PageDown'
		]

		if (!forbidden.some(s => s === key) && !ctrlKey && !altKey) {
			if (key === 'Backspace') {
				this.setState(state => state.userInput = state.userInput.slice(0, -1))
			} else if (key === 'Escape') {
				this.setState({ userInput: '' })
			} else if (key === 'ArrowUp' || key === 'ArrowLeft') {
				const { commandHistory, commandHistoryIndex } = this.state
				const upperLimit = commandHistoryIndex >= commandHistory.length
				
				if (!upperLimit) {
					this.setState(state => ({
						commandHistoryIndex: state.commandHistoryIndex += 1,
						userInput: state.commandHistory[state.commandHistoryIndex - 1]
					}))
				}
			} else if (key === 'ArrowDown' || key === 'ArrowRight') {
				const { commandHistory, commandHistoryIndex } = this.state
				const lowerLimit = commandHistoryIndex === 0
				
				if (!lowerLimit) {
					this.setState(state => ({
						commandHistoryIndex: state.commandHistoryIndex -= 1,
						userInput: state.commandHistory[state.commandHistoryIndex - 1] || ''
					}))
				}
			} else if (key === 'Enter') {
				const { userInput } = this.state
				
				if (userInput.length) {
					this.setState(state => ({
						commandHistory: userInput === '' ? state.commandHistory : [userInput, ...state.commandHistory],
						commandHistoryIndex: 0,
						fieldHistory: [...state.fieldHistory, {text: userInput, isCommand: true}],
						userInput: ''
					}), () => this.handleInputEvaluation(userInput))
				} else {
					this.setState(state => ({
						fieldHistory: [...state.fieldHistory, {isCommand: true}]
					}))
				}				
			} else {
				this.setState(state => ({
					commandHistoryIndex: 0,
					userInput: state.userInput += key
				}))
			}
		}
	}
	handleInputEvaluation(input) {
		try {
			const evaluatedForArithmetic = Math.evaluate(input)

			if (!isNaN(evaluatedForArithmetic)) {
				return this.setState(state => ({fieldHistory: [...state.fieldHistory, {text: evaluatedForArithmetic}]}))
			}

			throw Error
		} catch (err) {
			const { recognizedCommands, giveError, handleInputExecution } = this
			const cleanedInput = input.toLowerCase().trim()
			const dividedInput = cleanedInput.split(' ')
			const parsedCmd = dividedInput[0]
			const parsedParams = dividedInput.slice(1).filter(s => s[0] !== '-')
			const parsedFlags = dividedInput.slice(1).filter(s => s[0] === '-')
			const isError = !recognizedCommands.some(s => s.command === parsedCmd)

			if (isError) {
				return this.setState(state => ({fieldHistory: [...state.fieldHistory, giveError('nr', input)]}))
			}

			return handleInputExecution(parsedCmd, parsedParams, parsedFlags)
		}
	}
	handleInputExecution(cmd, params = [], flags = []) {
		if (cmd === 'help') {
			if (params.length) {
				if (params.length > 1) {
					return this.setState(state => ({
						fieldHistory: [...state.fieldHistory, this.giveError('bp', {cmd: 'HELP', noAccepted: 1})]
					}))
				}
				
				const cmdsWithHelp = this.recognizedCommands.filter(s => s.help)
				
				if (cmdsWithHelp.filter(s => s.command === params[0]).length) {
					return this.setState(state => ({
						fieldHistory: [...state.fieldHistory, {
							text: cmdsWithHelp.filter(s => s.command === params[0])[0].help,
							hasBuffer: true
						}]
					}))
				} else if (this.recognizedCommands.filter(s => s.command === params[0]).length) {
					return this.setState(state => ({
						fieldHistory: [...state.fieldHistory, {
							text: [
								`No necesitas el comando HELP ${this.recognizedCommands.filter(s => s.command === params[0])[0].command.toUpperCase()}`,
								this.recognizedCommands.filter(s => s.command === params[0])[0].purpose
							],
							hasBuffer: true
						}]
					}))
				}
				
				return this.setState(state => ({
					fieldHistory: [...state.fieldHistory, this.giveError('up', params[0].toUpperCase())]
				}))
			}
			
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {
					text: [
						'Principales comandos:',
						...this.recognizedCommands
							.sort((a, b) => a.command.localeCompare(b.command))
							.filter(({ isMain }) => isMain)
							.map(({ command, purpose }) => `${command.toUpperCase()}${Array.from({length: 15 - command.length}, x => '.').join('')}${purpose}`),
						'',
						'Todos los comandos:',
						...this.recognizedCommands
							.sort((a, b) => a.command.localeCompare(b.command))
							.map(({ command, purpose }) => `${command.toUpperCase()}${Array.from({length: 15 - command.length}, x => '.').join('')}${purpose}`),
						'',
						'Para ayuda sobre un comando específico, escribe AYUDA <CMD>, por ejemplo HELP PROJECT.'
					],
					hasBuffer: true
				}]
			}))
		} else if (cmd === 'cls') {
			return this.setState({fieldHistory: []})
		} else if (cmd === 'start') {
			if (params.length === 1) {
				return this.setState(state => ({
					fieldHistory: [...state.fieldHistory, {text: `Abriendo ${params[0]}...`, hasBuffer: true}]
				}), () => window.open(/http/i.test(params[0]) ? params[0] : `https://${params[0]}`))
			}
			
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, this.giveError('bp', {cmd: 'START', noAccepted: 1})]
			}))
		} else if (cmd === 'date') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: `La fecha actual es: ${new Date(Date.now()).toLocaleDateString()}`, hasBuffer: true}]
			}))
		} else if (cmd === 'cmd') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: 'Abriendo otra consola', hasBuffer: true}]
			}), () => window.open('aleburgos.es/terminal'))
		} else if (cmd === 'theme') {
			const { setTheme } = this.props
			
			if (flags.length === 1 && (['-d', '-dark', '-l', '-light'].some(s => s === flags[0]))) {
				const themeToSet = flags[0] === '-d' || flags[0] === '-dark' ? 'dark' : 'light'
				
				return this.setState(state => ({
					fieldHistory: [...state.fieldHistory, {text: `Aplicando tema ${themeToSet.toUpperCase()}`, hasBuffer: true}]
				}), () => setTheme(themeToSet))
			}
			
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, this.giveError(!flags.length ? 'nf' : 'bf', 'THEME')]
			}))
		} else if (cmd === 'exit') {
			return window.location.href = '/'
		} else if (cmd === 'time') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: `La hora actual es: ${new Date(Date.now()).toLocaleTimeString()}`, hasBuffer: true}]
			}))
		} else if (cmd === 'about') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: [
					'Hola!',
					aboutData.description1,
					aboutData.description2
				], hasBuffer: true}]
			}))
		} else if (cmd === 'experience') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: [
					'Estudios:',
					...educationData.map(education => education.institution + '.............................' + education.course),
					'',
					'Trabajo:',
					...experienceData.map(experience => experience.company + '.............................' + experience.jobtitle + ' ' + experience.startYear + ' - ' + experience.endYear),

				], hasBuffer: true}]
			}))
		} else if (cmd === 'skills') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: [
					...skillsData.map(skill => skill)
				], hasBuffer: true}]
			}))
		} else if (cmd === 'contact') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: [
					'Email: aleburgosmoreno@gmail.com',
					'Web: aleburgos.es',
					'LinkedIn: @ale-burgos',
					'GitHub: @alejandroburgos',
				], hasBuffer: true}]
			}))
		} else if (cmd === 'projects') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {text: [
					...projectsData.map(project => project.projectName + ' - ' + project.projectDesc),
				], hasBuffer: true}]
			}))
		} else if (cmd === 'project') {
			if (params.length === 1) {
				return this.setState(state => ({
					fieldHistory: [...state.fieldHistory, {text: `Abriendo ${params[0]}...`, hasBuffer: true}]
				}), () => projectsData.filter(s => {
					if (s.projectName.toLocaleLowerCase() === params[0].toLocaleLowerCase()) {
						window.open(s.demo)
					}else{
						this.giveError('pr', 'PROJECT')
					}
				}))
			}
			
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, this.giveError('bp', {cmd: 'PROJECT', noAccepted: 1})]
			}))
		} else if (cmd === 'title') {
			return this.setState(state => ({
				fieldHistory: [...state.fieldHistory, {
					text: `Aplica el nombre de la consola ${params.length > 0 ? params.join(' ') : '<BLANK>'}`,
					hasBuffer: true
				}]
			}), () => this.props.setTitle(params.length > 0 ? params.join(' ') : ''))
		}
	}
	handleContextMenuPaste(e) {
		e.preventDefault()
		
		if ('clipboard' in navigator) {
			navigator.clipboard.readText().then(clipboard => this.setState(state => ({
				userInput: `${state.userInput}${clipboard}`
			})))
		}
	}
	giveError(type, extra) {
		const err = { text: '', isError: true, hasBuffer: true}
		
		if (type === 'nr') {
			err.text = `${extra} : El término o expresion '${extra}' no esta reconocido. Revisa la ortografía y vuelve a intentarlo. Si no sabe qué comandos se reconocen, escriba HELP.`
		} else if (type === 'nf') {
			err.text = `El ${extra} el comando requiere el uso de banderas. Si no sabe qué banderas se pueden usar, escriba HELP ${extra}.`
		} else if (type === 'bf') {
			err.text = `Las banderas que proporcionaste ${extra} no son válidas. Si no sabe qué banderas se pueden usar, escriba HELP ${extra}.`
		} else if (type === 'bp') {
			err.text = `El ${extra.cmd} requiere ${extra.noAccepted} parametro(s). Si no sabe qué parámetros usar, escriba HELP ${extra.cmd}.`
		} else if (type === 'up') {
			err.text = `El comando ${extra} no existe para HELP.`
		} else if (type === 'pr') {
			err.text = `El proyecto ${extra} no existe.`
		}
		
		return err
	}
	render() {
		const { theme } = this.props
		const { fieldHistory, userInput } = this.state
		
		return <div
					id="field"
					className={theme.app.backgroundColor === '#333444' ? 'dark' : 'light'}
					style={theme.field}
					onKeyDown={e => this.handleTyping(e)}
					tabIndex={0}
					onContextMenu={e => this.handleContextMenuPaste(e)}
				>
			{fieldHistory.map(({ text, isCommand, isError, hasBuffer }) => {
				if (Array.isArray(text)) {
					return <MultiText input={text} isError={isError} hasBuffer={hasBuffer}/>
				}
				
				return <Text input={text} isCommand={isCommand} isError={isError} hasBuffer={hasBuffer}/>
			})}
			<UserText input={userInput} theme={theme.cursor}/>
		</div>
	}
}
const Text = ({ input, isCommand, isError, hasBuffer }) => <>
	<div>
		{isCommand && <div id="query"> root C:\Usuarios\Invitado> </div>}
		<span className={!isCommand && isError ? 'error' : ''}>{input}</span>
	</div>
	{hasBuffer && <div></div>}
</>
const MultiText = ({ input, isError, hasBuffer }) => <>
	{input.map(s => <Text input={s} isError={isError}/>)}
	{hasBuffer && <div></div>}
</>
const UserText = ({ input, theme }) => <div>
	<div id="query">root C:\Usuarios\Invitado></div>
	<span>{input}</span>
	<div id="cursor" style={theme}></div>
</div>


export default CMD
