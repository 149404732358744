export const contactsData = {
    email: 'aleburgosmoreno@gmail.com',
    address: 'Alhaurín el Grande, Málaga',
    sheetAPI: '',
    es: {
        phone: 'Pedir por correo',
    },
    en: {
        phone: 'Ask by email',
    }
}