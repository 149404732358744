import React, { useContext, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormControlLabel, Grid, Switch } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { makeStyles, styled } from '@material-ui/core/styles';
import { AiOutlineHome } from "react-icons/ai";
import { ConfigContext } from "../../App";

import './Pricing.scss'
import { ThemeContext } from '../../contexts/ThemeContext';
import { newWebDataPrices } from '../../data/newWebDataPrices'
import { headerData } from '../../data/headerData'

import { PricesCard } from '../../components/Prices/PricesCard';
import Accordions from '../../components/Accordion/Accordion';
import { Navbar } from '../../components';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.primary,
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));
  
function NewWordpress() {

    const { theme } = useContext(ThemeContext);
    const configLanguage = useContext(ConfigContext);
    const language = {
        en: {
            title: 'Price plan',
            monthly: 'Monthly',
            year: 'Year',
            pay: 'All payments must be made between the 1st and the 5th of each month.' ,
            maintenance: 'Maintenance',
            details: 'Details',
            placeholder: {
                name: 'Name',
                email: 'Email',
                message: 'Message',
                message2: 'Write your message here...',
            }
        },
        es: {
            title: 'Precios',
            monthly: 'Mes',
            year: 'Año',
            pay: 'Todos los pagos deben realizarse entre el 1 y el 5 de cada mes.' ,
            maintenance: 'Mantenimiento',
            details: 'Detalles',
            placeholder: {
                name: 'Nombre',
                email: 'Correo',
                message: 'Mensaje',
                message2: 'Escribe tu mensaje aquí...',
            }
        }
    } 
    
    return (
        <>
            <div style={{backgroundColor: theme.secondary}}>
                <Helmet>
                    <title>{headerData.name} | {language[configLanguage.lang].title}</title>
                </Helmet>

                <div className="mt-4">
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        {newWebDataPrices.map((prices) => (
                            prices[configLanguage.lang].map((price) => (
                                <PricesCard
                                    theme={theme}
                                    key={price.id}
                                    id={price.id} 
                                    title={price.priceName}
                                    img={price.img}
                                    data={price.data}
                                    price={price.price}
                                    lang={configLanguage.lang}
                                />
                            ))
                        ))}
                    </Grid>
            </div> 
            <hr className="mt-4 mb-4 text-center" style={{color: theme.tertiary, width: '60%', margin: "auto"}}/>
                <Grid item xs={12} md={12} xl={12}>
                    <h2 className="text-center" style={{color: theme.tertiary}}>{language[configLanguage.lang].details}</h2>
                    <Accordions 
                        theme={theme} 
                        data={newWebDataPrices}
                        lang={configLanguage.lang}/>
                </Grid>
                
            </div>
        </>
    )
}

export default NewWordpress
