import React, { createContext, useContext, useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { ThemeContext } from './contexts/ThemeContext';
import { Main, BlogPage, ProjectPage, Terminal } from './pages'
import { BackToTop } from './components'

import './App.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

import Pricing from './pages/Pricing/Pricing';
import WordpressPage from './pages/Wordpress/WordpressPage';
import { HomePricing } from './pages/Pricing/HomePricing';

export const ConfigContext = createContext({
  lang: 'es',
  setLang: () => {}
});

function App() {
  
  const { theme } = useContext(ThemeContext);
  const [language, setLanguage] = useState('es')
  
  useEffect(() => {
    if (!localStorage.getItem('lang') || localStorage.getItem('lang') === 'undefined' || localStorage.getItem('lang') === 'null') {
      localStorage.setItem('lang', language);
    } else {
      setLanguage(localStorage.getItem('lang'))
    }
  }, [])
  

  const setLang = (selectedLang) => {
    if (!selectedLang) {
      localStorage.setItem('lang', 'es');
      setLanguage('es');
    } else {
      localStorage.setItem('lang', selectedLang);
      setLanguage(selectedLang);
    }
    // add in localStorage
    localStorage.setItem('lang', selectedLang);
  }

  console.log("%cALEJANDRO BURGOS", `color:${theme.primary}; font-size:50px`);

  return (
    <ConfigContext.Provider value={{
      lang: language,
      setLang: setLang
    }}>
      <div className="app">
        <Router>
          {/* <ScrollToTop/> */}
          <Routes>
            <Route path="/" exact element={<Main />} />
            <Route path="/blog" exact element={<BlogPage />} />
            <Route path="/projects" exact element={<ProjectPage />} />
            <Route path="/wordpress-projects" exact element={<WordpressPage />} />
            <Route path="/terminal" exact element={<Terminal />} /> 
            <Route path="/pricing-wordpress" exact element={<HomePricing />} />
            {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
          </Routes>
        </Router>
        <BackToTop />
      </div>
    </ConfigContext.Provider>
  );
}

export default App;
