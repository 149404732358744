import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const wordpressPagesData = [{
    es: [{
            id: 0,
            projectName: 'Mata Mua - Playa',
            projectDesc: 'Carta, menú y reservas online',
            tags: [],
            demo: 'https://matamuaplaya.com/',
            image: 'https://matamuaplaya.com/wp-content/uploads/2018/09/Marca_MataMua-300x103.png'
        },
        {
            id: 1,
            projectName: 'TropicSur',
            projectDesc: 'Producción de aguacates y mangos',
            tags: [],
            demo: 'https://www.tropicsur.es/',
            image: 'https://i.imgur.com/C4OS7cw.png'
        },
        {
            id: 2,
            projectName: 'Meriloban - Moda Mujer',
            projectDesc: 'Tienda de moda mujer',
            tags: [],
            demo: 'https://meriloban.com/',
            image: 'https://meriloban.com/wp-content/uploads/2021/11/logo-malo.png'
        },
        {
            id: 3,
            projectName: 'Kan Aima - Boat Trips',
            projectDesc: 'Excursiones en barco por la costa de Benalmádena, Málaga',
            tags: [],
            demo: 'http://kanaimaboat.com/',
            image: 'http://kanaimaboat.com/wp-content/uploads/2022/03/kanaima_logo_ok-1-2048x792.png'
        },
        {
            id: 4,
            projectName: 'Nosha Vip club - Discoteca',
            projectDesc: 'Discoteca en Málaga',
            tags: [],
            demo: 'https://noshavipclub.com/',
            image: 'https://noshavipclub.com/wp-content/themes/nosha/images/logo.png'
        },
        {
            id: 5,
            projectName: 'Royal Crypto',
            projectDesc: 'Campañas de marketing',
            tags: [],
            demo: 'https://royalcrypto.es/',
            image: 'https://royalcrypto.es/wp-content/uploads/2022/04/Logotipo-RoyalCrypto-Negativo-PNG_Mesa-de-trabajo-1.png'
        },
        {
            id: 6,
            projectName: 'Bárbara Serrano fotografía',
            projectDesc: 'Fotografías de familias y bebés',
            tags: [],
            demo: 'https://barbaraserrano.es/',
            image: 'https://barbaraserrano.es/wp-content/uploads/2021/11/Ba%CC%81rbara-Serranon-logo.png'
        },
        {
            id: 7,
            projectName: 'Gruff Music - Discográfica',
            projectDesc: 'Música house y sus subgéneros',
            tags: [],
            demo: 'https://gruffmusic.com/',
            image: 'https://gruffmusic.com/wp-content/uploads/2017/06/Logo-SoundRise-White.png'
        },
        {
            id: 8,
            projectName: 'Remanclima',
            projectDesc: 'Instalación y mantenimiento de aire acondicionados',
            tags: [],
            demo: 'http://remanclima.com/',
            image: 'http://remanclima.com/wp-content/uploads/2022/06/LOGO-Remanclima_Mesa-de-trabajo-1-1.png'
        },
        {
            id: 9,
            projectName: 'Karnivoro - Restaurante',
            projectDesc: 'Restaurante de carnes',
            tags: [],
            demo: 'https://karnivororestaurant.com/',
            image: 'https://karnivororestaurant.com/wp-content/uploads/2022/09/KARNIVORO-logo-pequeno.png'
        }],
    en: [{
            id: 0,
            projectName: 'Mata Mua - Playa',
            projectDesc: 'Menu, menu and online reservations',
            tags: [],
            demo: 'https://matamuaplaya.com/',
            image: 'https://matamuaplaya.com/wp-content/uploads/2018/09/Marca_MataMua-300x103.png'
        },
        {
            id: 1,
            projectName: 'TropicSur',
            projectDesc: 'Avocado and mango production',
            tags: [],
            demo: 'https://www.tropicsur.es/',
            image: 'https://i.imgur.com/C4OS7cw.png'
        },
        {
            id: 2,
            projectName: 'Meriloban - Women\'s Fashion',
            projectDesc: 'Women\'s fashion store',
            tags: [],
            demo: 'https://meriloban.com/',
            image: 'https://meriloban.com/wp-content/uploads/2021/11/logo-malo.png'
        },
        {
            id: 3,
            projectName: 'Kan Aima - Boat Trips',
            projectDesc: 'Boat trips along the coast of Benalmádena, Málaga',
            tags: [],
            demo: 'http://kanaimaboat.com/',
            image: 'http://kanaimaboat.com/wp-content/uploads/2022/03/kanaima_logo_ok-1-2048x792.png'
        },
        {
            id: 4,
            projectName: 'Nosha Vip club - Nightclub',
            projectDesc: 'Nightclub in Málaga',
            tags: [],
            demo: 'https://noshavipclub.com/',
            image: 'https://noshavipclub.com/wp-content/themes/nosha/images/logo.png'
        },
        {
            id: 5,
            projectName: 'Royal Crypto',
            projectDesc: 'Marketing campaigns',
            tags: [],
            demo: 'https://royalcrypto.es/',
            image: 'https://royalcrypto.es/wp-content/uploads/2022/04/Logotipo-RoyalCrypto-Negativo-PNG_Mesa-de-trabajo-1.png'
        },
        {
            id: 6,
            projectName: 'Bárbara Serrano Photography',
            projectDesc: 'Family and baby photos',
            tags: [],
            demo: 'https://barbaraserrano.es/',
            image: 'https://barbaraserrano.es/wp-content/uploads/2021/11/Ba%CC%81rbara-Serranon-logo.png'
        },
        {
            id: 7,
            projectName: 'Gruff Music - Record Label',
            projectDesc: 'House music and its subgenres',
            tags: [],
            demo: 'https://gruffmusic.com/',
            image: 'https://gruffmusic.com/wp-content/uploads/2017/06/Logo-SoundRise-White.png'
        },
        {
            id: 8, 
            projectName: 'Remanclima',
            projectDesc: 'Installation and maintenance of air conditioning',
            tags: [],
            demo: 'http://remanclima.com/',
            image: 'http://remanclima.com/wp-content/uploads/2022/06/LOGO-Remanclima_Mesa-de-trabajo-1-1.png'
        },
        {
            id: 9,
            projectName: 'Karnivoro - Restaurant',
            projectDesc: 'Meat restaurant',
            tags: [],
            demo: 'https://karnivororestaurant.com/',
            image: 'https://karnivororestaurant.com/wp-content/uploads/2022/09/KARNIVORO-logo-pequeno.png'
        }],
}]
