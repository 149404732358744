import React,{useContext} from 'react';

import { ThemeContext } from '../../contexts/ThemeContext';
import { ConfigContext } from "./../../App";

import './Experience.css';

import { experienceData } from '../../data/experienceData'
import ExperienceCard from './ExperienceCard';

function Experience() {
    const configLanguage = useContext(ConfigContext);
    const { theme } = useContext(ThemeContext);
    const language = {
        en: {
            title: 'Experience',
        },
        es: {
            title: 'Experiencia',
        }
    } 
    return (
        <div className="experience" id="experience" style={{backgroundColor: theme.secondary}}> 
             <div className="experience-body">
                 <div className="experience-image">
                     <img src={theme.expimg} alt="" />
                 </div>
                 <div className="experience-description">
                    <h1 style={{color:theme.primary}}>{language[configLanguage.lang].title}</h1>
                    {experienceData.map(exp =>{
                        return (
                            <>
                                {exp[configLanguage.lang].map(exp => (
                                    <ExperienceCard
                                        key={exp.id}
                                        id={exp.id}
                                        company={exp.company}
                                        position={exp.position}
                                        startYear={exp.startYear}
                                        endYear={exp.endYear}
                                        description={exp.description}
                                    />
                                ))}
                            </>
                        )
                    })}
                 </div>
             </div>
        </div>
    )
}

export default Experience
