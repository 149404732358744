import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [{
    es: [{
            id: 0,
            projectName: 'Aviario',
            projectDesc: 'Control de crecimiento de aves y calendario. Pruebas -> usuario: sawer password: 1111',
            tags: ['React', 'SCSS', 'Node JS', 'MongoDB'],
            code: 'https://github.com/alejandroburgos/aviario.aleburgos',
            demo: 'https://aviary.aleburgos.es',
            image: one
        },
        {
            id: 1,
            projectName: 'Tiempo',
            projectDesc: 'El tiempo en todo pronostico. Con datos muy detallados.',
            tags: ['React', 'SCSS', 'openweather'],
            code: 'https://github.com/alejandroburgos/app-weather-aleburgos',
            demo: 'https://weather.aleburgos.es',
            image: eight
        },
        {
            id: 2,
            projectName: 'Consola',
            projectDesc: 'Consola que replica un curriculum.',
            tags: ['React', 'SCSS'],
            code: '',
            demo: 'https://console.aleburgos.es/terminal',
            image: two
        },
        {
            id: 3,
            projectName: 'Calendario',
            projectDesc: 'Calendario con eventos y notas.',
            tags: ['React', 'SCSS', 'Node JS', 'MongoDB'],
            code: '',
            demo: 'https://calendar.aleburgos.es',
            image: three
        }
    ],
    en: [{
            id: 0,
            projectName: 'Aviary',
            projectDesc: 'Growth control of birds and calendar. Tests -> user: sawer password: 1111',
            tags: ['React', 'SCSS', 'Node JS', 'MongoDB'],
            code: 'https://github.com/alejandroburgos/aviario.aleburgos',
            demo: 'https://aviary.aleburgos.es',
            image: one
        },
        {
            id: 1,
            projectName: 'Weather',
            projectDesc: 'The weather in all forecast. With very detailed data.',
            tags: ['React', 'SCSS', 'openweather'],
            code: 'https://github.com/alejandroburgos/app-weather-aleburgos',
            demo: 'https://weather.aleburgos.es',
            image: eight
        },
        {
            id: 2,
            projectName: 'Console',
            projectDesc: 'Console that replicates a curriculum.',
            tags: ['React', 'SCSS'],
            code: '',
            demo: 'https://console.aleburgos.es/terminal',
            image: two
        },
        {
            id: 3,
            projectName: 'Calendar',
            projectDesc: 'Calendar with events and notes.',
            tags: ['React', 'SCSS', 'Node JS', 'MongoDB'],
            code: '',
            demo: 'https://calendar.aleburgos.es',
            image: three
        }
        ]
}]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/