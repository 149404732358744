import cv_alejandro_burgos from '../assets/pdf/CV Alejandro Burgos Moreno 06-2022.pdf'
import imageProfile from '../assets/png/aleburgos.png'
// get year and get diference of 2018
const getYear = () => new Date().getFullYear()
const getDifference = getYear() - 2018
export const headerData = {
    name: 'Alejandro Burgos',
    title: "Frontend Developer",
    image: imageProfile,
    resumePdf: cv_alejandro_burgos,
    es: {
        description:`Desarrollador front end con ${getDifference} años de experiencia, centrado en la creación de códigos claros y con capacidad para cumplir los objetivos y las necesidades del cliente y/o empresa.`,
    },
    en: {
        description:`Front end developer with ${getDifference} years of experience, focused on creating clear codes and able to meet the goals and needs of the client and/or company.`,
    }
}
