import React from 'react'
import CountUp from 'react-countup';
import './PricesCard.css'

export const PricesCard = ({id, title, data ,img, price, theme, lang, typePrice}) => {
    const language = {
        en: {
            m: 'month',
            y: 'year',
            from: 'from',
            gift_text1: '15 days of gift!',
            gift_text2: 'a month of gift!',
            plugins_update: 'Actualizaciones de plugins',
            change_designs: 'Diseño web ',
            woocomerce_settings: 'Ajustes del comercio',
            remove_virus: 'Eliminación de virus',
            add_products: "Add products",
            custom_designs: 'Customs designs',
            backups: "Backups",
            social_settings: 'Social settings'
        },
        es: {
            m: 'mes',
            y: 'año',
            from: 'desde',
            gift_text1: '¡15 días de regalo!',
            gift_text2: '¡Un mes de regalo!',
            plugins_update: 'Actualizaciones de plugins',
            change_designs: 'Diseño web ',
            woocomerce_settings: 'Ajustes del comercio',
            remove_virus: 'Eliminación de virus',
            add_products: 'Agregar productos',
            custom_designs: 'Diseños personalizados',
            backups: 'Copias de seguridad',
            social_settings: 'Ajustes en redes sociales'
        }
    } 
  return (
        <div className="cards" id={title} key={id}>
            <div className={id !== 1 ? 'card shadow' : 'card active'} 
                style={{ background: id === 1 && `linear-gradient(135deg, #46464b 0%,#212125 100%)`}}>
                <ul>
                    <li className="pack">{title}</li>
                    <li>
                        <small>{language[lang].from}</small>
                    </li>
                    <li className={typePrice === 'y' ? `price`: `price bottom-bar`}>
                        <CountUp
                            start={0}
                            end={price}
                            duration={2.5}
                            deplay={2}
                            separator=""
                            decimals={0}
                            decimal=","
                            prefix=""
                            suffix="€"
                        />
                    
                        <h4 style={{fontSize: '15px !important'}}>{language[lang][typePrice]}</h4>
                    </li>
                    {typePrice === 'y' && <li className="price bottom-bar" style={{marginTop: "-38px", color: theme.primary}}>
                        <h6>{id===1 ? language[lang].gift_text1 : id===2 ? language[lang].gift_text2 : ''}</h6>
                    </li>}
                    
                    {data.map((dto, i) => (
                        <div key={i}>
                            {dto.change_designs && <li className="bottom-bar">{language[lang].change_designs}</li>}
                            {dto.plugins_update && <li className="bottom-bar">{dto.designs} {language[lang].plugins_update} </li>}
                            {dto.remove_virus && <li className="bottom-bar">{language[lang].remove_virus}</li>}
                            {dto.woocomerce_settings && <li className="bottom-bar">{language[lang].woocomerce_settings}</li>}
                            {dto.add_products && <li className="bottom-bar">{language[lang].add_products} ({dto.add_products})</li>}
                            {dto.custom_designs && <li className="bottom-bar">{language[lang].custom_designs} ({dto.custom_designs})</li>}
                            {dto.backups && <li className="bottom-bar">{language[lang].backups} ({dto.backups})</li>}
                            {dto.social_settings && <li className="bottom-bar">{language[lang].social_settings}</li>}
                        </div>
                    ))}
                    {/* <li><button className="btn">Learn More</button></li> */}
                </ul>
            </div>
        </div>
  )
}
